import { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { object, string } from "yup"; 
import { tryFetch } from "Helpers/FetchAPI"; 

export const useFgPass = (enqueueSnackbar: (message: string, options?: any) => void) => {
  const recaptcha = useRef<ReCAPTCHA>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [timer, setTimer] = useState<number | null>(null);

  const initialValues = {
    email_address: "",
    g_token: "",
  };

  const validationSchema = object({
    email_address: string().email("Invalid email address").required("Email is required"),
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (timer && timer > 0) {
      interval = setInterval(() => setTimer((prev) => (prev ? prev - 1 : null)), 1000);
    } else if (timer === 0) {
      setButtonDisabled(false);
      setTimer(null);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleSubmit = async (values: typeof initialValues) => {
    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      const token = await recaptcha.current?.executeAsync();
      if (!token) {
        throw new Error("ReCAPTCHA verification failed. Please try again.");
      }

      values.g_token = token;

      const response = await tryFetch({
        url: "/sec/forgot-password",
        method: "POST",
        payload: values,
      });

      if (response.is_success) {
        enqueueSnackbar("A password reset link has been sent to your email.", { variant: "success" });
        setButtonDisabled(true);
        setTimer(300);
      } else {
        setErrorMessage(response.error || "Failed to process your request.");
        enqueueSnackbar(response.error || "Failed to process your request.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error during request:", error);
      const errorMsg = (error as Error).message || "An error occurred. Please try again.";
      setErrorMessage(errorMsg);
      enqueueSnackbar(errorMsg, { variant: "error" });
    } finally {
      recaptcha.current?.reset();
      setIsSubmitting(false);
    }
  };

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    recaptcha,
    isSubmitting,
    errorMessage,
    buttonDisabled,
    timer,
  };
};
