import React, { useState, useEffect } from 'react';
import '../../styles/FAQs.scss';

interface FAQ {
  id: string;
  title: string;
  content: {
    id: string;
    question: string;
    answer: string;
  }[];
}

const FAQs: React.FC = () => {
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    fetch('/assets/faq.json')
      .then(response => response.json())
      .then(data => setFaqs(data.faqs))
      .catch(error => console.error('Error fetching FAQ data:', error));
  }, []);

  const toggleAnswer = (id: string) => {
    setActiveId(id === activeId ? null : id);
  };

  return (
    <div className="faq-page">
      <header className="faq-header">
        <h1>FAQs</h1>
      </header>
      {faqs.map((section) => (
        <div key={section.id} className="faq-section">
          <h2 className="faq-title">{section.title}</h2>
          {section.content.map((item) => (
            <div key={item.id} className="faq-item">
              <div className="faq-question" onClick={() => toggleAnswer(item.id)}>
                <span>{item.question}</span>
                <button className="toggle-button">
                  {activeId === item.id ? '×' : '+'}
                </button>
              </div>
              {activeId === item.id && (
                <div className="faq-answer">
                  <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                </div>
              )}
              <hr className="faq-divider" />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FAQs;
