import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

interface MapProps {
  center: { lat: number; lng: number };
  onMapLoad?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const Map: React.FC<MapProps> = ({ center, onMapLoad, children }) => {
  const API_KEY = process.env.REACT_APP_GM || "";

  return (
    <LoadScript googleMapsApiKey={API_KEY} libraries={["places"]}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={15}
        onLoad={(map) => onMapLoad && onMapLoad(map)}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
