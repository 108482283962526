import React, { useEffect, useState } from 'react';
import '../../../styles/Order.scss';
import { MdChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

interface OrderItem {
  productId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

interface Order {
  orderId: string;
  items: OrderItem[];
  totalAmount: number;
}

export default function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {

    fetch('/assets/order.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setOrders(data);
      })
      .catch((error) => {
        console.error('Error fetching order data:', error);
      });
  }, []);

  return (
    <div className="order-page">
      <h2 className="order-header">Your Orders</h2>
      <div className="orders-container">
        {orders.map((order, index) => (
          <div key={index} className="order-card">
            <div className="order-details">
              <p className="order-id">ORDER ID: {order.orderId}</p>
              <p className="order-products">
                {order.items.map((item) => item.name).join(', ').slice(0, 25)}...
                </p>
              <p className="order-items">ITEMS: {order.items.reduce((acc, item) => acc + item.quantity, 0)}</p>
              <p className="order-amount">{order.totalAmount.toFixed(2)}</p>
            </div>
            <Link to={`/track/${order.orderId}`} className="order-action">
              <MdChevronRight size={24} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}