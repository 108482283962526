import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FavoritesContext } from '../../../Context/favoritesContext';
import { FaHeart } from 'react-icons/fa';
import '../../../styles/Home.scss';
import { tryFetch } from '../../../Helpers/FetchAPI';

const FavoritesSection: React.FC = () => {
  const favoritesContext = useContext(FavoritesContext);
  const likedProducts = favoritesContext?.likedProducts || {};
  const [likedItems, setLikedItems] = useState<any[]>([]);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('/assets/product.json');
        const data = await response.json();

        const allProducts = data.merchants.flatMap((merchant: any) => merchant.products);

        const likedProductIds = Object.keys(likedProducts).filter(
          (id) => likedProducts[id]
        );

        const likedItemsList = allProducts.filter((product: any) =>
          likedProductIds.includes(product.product_id)
        );

        setLikedItems(likedItemsList);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [likedProducts]);

  // useEffect(() => {
  //   const fetchLikedProducts = async () => {
  //     try {
  //       const likedProductIds = Object.keys(likedProducts).filter(
  //         (id) => likedProducts[id]
  //       );

  //       const products: any[] = [];
  //       for (const productId of likedProductIds) {
  //         const [merchantId] = productId.split('-');

  //         const response = await tryFetch({
  //           url: `/merchants/${merchantId}/products`,
  //           method: 'GET',
  //         });

  //         const merchantProducts = response?.data || [];
  //         const product = merchantProducts.find(
  //           (p: any) => p.product_id === productId
  //         );

  //         if (product) {
  //           products.push(product);
  //         }
  //       }

  //       setLikedItems(products);
  //     } catch (error) {
  //       console.error('Error fetching liked products:', error);
  //     }
  //   };

  //   fetchLikedProducts();
  // }, [likedProducts]);

  const handleImageLoad = (id: string) => {
    setLoadedImages((prev) => ({ ...prev, [id]: true }));
  };

  const truncateText = (text: string, maxLength: number): string =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  return (
    <div className="favorites">
      <h2>Your Wishlist</h2>
      <div className="favorites-list">
        {likedItems.length > 0 ? (
          likedItems.map((product) => (
            <div key={product.product_id} className="favorite-item">
              <Link to={`/product/${product.product_id}`}>
                <img
                  src={product.media[0]?.url}
                  alt={product.name}
                  className={`favorite-image ${
                    loadedImages[`favorite-${product.product_id}`] ? 'loaded' : ''
                  }`}
                  onLoad={() => handleImageLoad(`favorite-${product.product_id}`)}
                />
                <p className="favorite-name">{truncateText(product.name, 12)}</p>
                <p className="favorite-price">
                  {product.variants[0].price.toFixed(2)}
                </p>
              </Link>
              <button
                className="heart-icon"
                onClick={() => favoritesContext?.toggleLike(product.product_id)}
              >
                <FaHeart color="#832b99" size="22" />
              </button>
            </div>
          ))
        ) : (
          <p>Create your wishlist – Like it and Gottit!</p>
        )}
      </div>
    </div>
  );
};

export default FavoritesSection;
