import React, { useState, useEffect } from "react";
import '../../styles/T_addContact.scss';
import DragableMap from "../map/dragableMap";

interface AddressInfo {
    street_address_1: string;
    street_address_2: string;
    state_municipality: string;
    city: string;
    zip_code: string;
    landmark?: string;
    coordinates: { lat: number; lng: number };
  }
  
  interface ContactInfo {
    contact_id: string;
    contact_name: string;
    contact_number: string;
    address_info: AddressInfo;
    label: string;
    default: boolean;
  }

interface AddContactModalProps {
  contact?: ContactInfo;
  onClose: () => void;
}

const AddContactModal: React.FC<AddContactModalProps> = ({ contact, onClose }) => {
    const [formData, setFormData] = useState({
        contactName: "",
        contactNumber: "",
        streetAddress1: "",
        streetAddress2: "",
        city: "",
        municipality: "",
        zipCode: "",
        landmark: "",
        coordinates: { lat: 0, lng: 0 },
        label: "",
        default: false,
    });

    useEffect(() => {
        if (contact) {
          setFormData({
            contactName: contact.contact_name,
            contactNumber: contact.contact_number,
            streetAddress1: contact.address_info.street_address_1,
            streetAddress2: contact.address_info.street_address_2,
            city: contact.address_info.city,
            municipality: contact.address_info.state_municipality,
            zipCode: contact.address_info.zip_code,
            landmark: contact.address_info.landmark || "",
            coordinates: contact.address_info.coordinates || { lat: 0, lng: 0 },
            label: contact.label,
            default: contact.default          });
        }
      }, [contact]);

      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleLocationChange = (details: {
        coordinates: { lat: number; lng: number };
        city: string;
        municipality: string;
        streetAddress1: string;
        streetAddress2: string;
      }) => {
        setFormData((prevData) => ({
            ...prevData,
            streetAddress1: details.streetAddress1,
            streetAddress2: details.streetAddress2,
            city: details.city,
            municipality: details.municipality,
            coordinates: details.coordinates,
          }));
        };

        const handleSave = () => {
          const contactData = {
            contact_id: contact?.contact_id,
            contact_name: formData.contactName,
            contact_number: formData.contactNumber,
            address_info: {
              coordinates: formData.coordinates,
              street_address_1: formData.streetAddress1,
              street_address_2: formData.streetAddress2,
              state_municipality: formData.municipality,
              city: formData.city,
              zip_code: formData.zipCode,
              landmark: formData.landmark,
            },
            label: contact?.label || 'home',
            default: contact?.default || false,
          };
        
          onClose();
        };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="modal-header">Contact Information</h2>
        <input
          type="text"
          placeholder="Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="Contact Number"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleInputChange}
        />
        <div className="search-bar">
          <DragableMap onLocationChange={handleLocationChange} />
        </div>
        <input
          type="text"
          placeholder="Street Address 1"
          name="streetAddress1"
          value={formData.streetAddress1}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="Street Address 2"
          name="streetAddress2"
          value={formData.streetAddress2}
          onChange={handleInputChange}
        />
        <input type="text" placeholder="City" name="city" value={formData.city} onChange={handleInputChange} />
        <input
          type="text"
          placeholder="Municipality"
          name="municipality"
          value={formData.municipality}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="Zip Code"
          name="zipCode"
          value={formData.zipCode}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="Enter other Details (e.g. Landmark)"
          name="landmark"
          value={formData.landmark}
          onChange={handleInputChange}
        />
        <button className="modal-save-button" onClick={handleSave}>
          Save
        </button>
        <button className="modal-close-button" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddContactModal;
