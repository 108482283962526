interface IKeyVal {
    key: string,
    value: string
}

export const getEnv = (key: string): string | null => {
    const env: IKeyVal[] = [
        { key: "API_ENDPOINT", value: process.env.REACT_APP_API_ENDPOINT as string },
        { key: "APP_KEY", value: process.env.REACT_APP_APP_KEY as string },
        { key: "SIGNATURE_SECRET", value: process.env.REACT_APP_SIGNATURE_SECRET as string },
        { key: "GOOGLE_RECAPTCHA", value: process.env.REACT_APP_RC as string },
    ];

    const result = env.find((x:IKeyVal) => x.key === key);
    return typeof(result) !== "undefined" ? result.value : null;
}