export const getTokenCookie = (name: string) => {
    const cookieArr = document.cookie.split("; ");
    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if(cookiePair[0] === name) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
}

export const setModules = (modules: string) => {
    localStorage.setItem("_sys_mods", modules);
}

export const getItem = (key: string) => {
    return localStorage.getItem(key);
}

export const getTokenOrDefault = () => {
    return getTokenCookie("_SESSION_MPLACE") ?? "";
}

export const getModulesOrDefault = () => {
    try {
        const mods = getItem("_sys_mods");
        if(mods === null) {
            return [];
        }

        const m = JSON.parse(mods);
        return m;
    }catch(err){
        return [];
    }
    
}

export const resetStorage = () => {
    localStorage.removeItem("_sys_mods");
}