import React, { createContext, useContext, useState } from 'react';

export type CartItem = {
  productId: string;
  productName: string;
  productMedia: string[];
  price: number;
  quantity: number;
  availableStock: number;
  variantDetails: { size?: string; color?: string };
  merchant: { id: string; merchant_name: string; hero_url: string };
};

type CartContextType = {
  cartItems: CartItem[];
  addToCart: (item: CartItem) => void;
  updateQuantity: (productId: string, productName: string, variantDetails: { size?: string; color?: string }, delta: number) => void;
  removeItem: (itemIndex: number) => void;
  getTotalAmount: () => number;
  getTotalAmountForMerchant: (merchantId: string) => number;
};

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  const addToCart = (item: CartItem) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(i => 
          i.productId === item.productId &&
          i.productName === item.productName &&
          i.variantDetails.size === item.variantDetails.size &&
          i.variantDetails.color === item.variantDetails.color &&
          i.merchant.id === item.merchant.id
      );
      if (existingItem) {
        return prevItems.map(i =>
          i.productId === item.productId &&
          i.productName === item.productName &&
          i.variantDetails.size === item.variantDetails.size &&
          i.variantDetails.color === item.variantDetails.color &&
          i.merchant.id === item.merchant.id
            ? { ...i, quantity: i.quantity + item.quantity }
            : i
        );
      }
      return [...prevItems, item];
    });
  };

  const updateQuantity = (productId: string, productName: string, variantDetails: { size?: string; color?: string }, delta: number) => {
    setCartItems(prevItems =>
      prevItems.map(item =>
        item.productName === productName && item.productId === productId &&
        item.variantDetails.size === variantDetails.size &&
        item.variantDetails.color === variantDetails.color
          ? { ...item, quantity: Math.max(1, item.quantity + delta) }
          : item
      )
    );
  };
  const removeItem = (itemIndex: number) => {
    setCartItems(prevItems => prevItems.filter((_, index) => index !== itemIndex));
  };

  const getTotalAmount = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const getTotalAmountForMerchant = (merchantId: string) => {
    return cartItems
      .filter((item) => item.merchant.id === merchantId)
      .reduce((total, item) => total + item.price * item.quantity, 0);
  };



  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateQuantity,
        removeItem,
        getTotalAmount,
        getTotalAmountForMerchant,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
