import React, { createContext, useContext } from 'react'

export type IPageState = {
    isPageLoading: boolean,
    setIsPageLoading: Function
}

export const PageContext = createContext<IPageState>({
    isPageLoading: false,
    setIsPageLoading: () => { }
})

export default function PageContextProvider(props: any) {
    const { value, children } = props;
    const { isPageLoading, setIsPageLoading } = value;

    return (
        <PageContext.Provider value={{ isPageLoading, setIsPageLoading }}>
            {children}
        </PageContext.Provider>
    )
}

export const usePageContext = () => {
    const context = useContext(PageContext);
    if (!context) {
        throw new Error(
            "usePageContext must be unsed within a PageContextProvider"
        );
    }
    return context;
}