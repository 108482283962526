import React from 'react';
import '../../../styles/Home.scss'
import BannerSection from './bannerSection';
import FavoritesSection from './favoritesSection';
import FollowedMerchantsSection from './followedSection';
import MerchantSection from './merchantSection';

const Home: React.FC = () => {
  return (
    <div className="home">
      <BannerSection />
      <FavoritesSection />
      <FollowedMerchantsSection />
      <MerchantSection />
    </div>
  );
};

export default Home;
