import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/Navbar.scss';
import logo from '../Assets/logo.png';
import logoPro from '../Assets/logo-pro.png';
import { MdSearch, MdShoppingCart, MdFavorite, MdMenu } from 'react-icons/md';
import { FaCalendarCheck } from "react-icons/fa";
import { useCart } from '../../Context/CartContext';
import { FavoritesContext } from 'Context/favoritesContext';
import { useSessionContext } from 'Context/SessionContext';


const Navbar: React.FC = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [searchText, setSearchText] = useState('');

	const location = useLocation();
	const { cartItems } = useCart();
	const { favCount } = useContext(FavoritesContext) || { favCount: 0 };
	const { session } = useSessionContext();

	const toggleMenu = () => setMenuOpen(!menuOpen);
	const clearSearch = () => setSearchText('');
	const closeMenu = () => setMenuOpen(false);
	const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<div className="navbar">
			<div className="nav-logo">
				<Link to="/">
					<img src={logo} alt="Primary Logo" />
				</Link>
				<img src={logoPro} alt="Pro Logo" />
			</div>
			<div className="nav-search">
				<div className="search-bar">
					<MdSearch size={24} className="search-icon" />
					<input type="text" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
					<span className="close-icon" onClick={clearSearch}>✕</span>
				</div>
			</div>
			<div className="nav-icons">
				{!session.isAuthenticated && (
					<Link to="/log-in" className="login-button">
						Log In
					</Link>
				)}
				<Link to={session.isAuthenticated ? "/cart" : "/log-in"} className="cart-icon-container">
					<MdShoppingCart size={24} className="cart-icon" />
					{session.isAuthenticated && cartCount > 0 && <span className="cart-count">{cartCount}</span>}
				</Link>
				{session.isAuthenticated && (
					<>
						<Link to="/favorites" className="fav-icon-container">
							<MdFavorite size={24} className="fav-icon" />
							{favCount > 0 && <span className="fav-count">{favCount}</span>}
						</Link>
						<Link to="/your-orders">
							<FaCalendarCheck size={20} className="track-icon" />
						</Link>
					</>
				)}
				<MdMenu size={28} onClick={toggleMenu} />
			</div>
			{menuOpen && (
				<>
					<div className="bur-overlay" onClick={closeMenu}></div>
					<div className="bur-menu">

						<ul>
							<li className='msearch'>
								<MdSearch size={24} className="msearch-icon" />
								<input type="text" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} className='msearch-bar' />
								<span className="mclose-icon" onClick={clearSearch}>✕</span>
							</li>
							<li className='context'><Link to={session.isAuthenticated ? "/profile" : "/log-in"} onClick={closeMenu}>View Profile</Link></li>
							<li ><Link to="/faqs" onClick={closeMenu}>FAQs</Link></li>
							<li ><Link to="/support" onClick={closeMenu}>Support</Link></li>
							<li ><Link to="/terms-and-conditions" onClick={closeMenu}>Terms and Conditions</Link></li>
							<li ><Link to="/return-refund-policy" onClick={closeMenu}>Retun/Refund Policy</Link></li>
							<li ><Link to="/end-user-license-agreement" onClick={closeMenu}>EULA</Link></li>
							<li ><Link to="/privacy-policy" onClick={closeMenu}>Privacy Policy</Link></li>

						</ul>
					</div>
				</>
			)}
		</div>
	);
};

export default Navbar;
