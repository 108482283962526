import * as Yup from 'yup';

const SupportSchema = Yup.object().shape({
  fullName: Yup.string().required('Please fill out your full name.'),
  email: Yup.string().email('Invalid email address').required('Please fill out your email.'),
  contactNumber: Yup.string().required('Please fill out your contact number.'),
  subject: Yup.string().required('Please fill out the subject.'),
  message: Yup.string().required('Please describe the issue.'),
  attachment: Yup.mixed().notRequired(),
});

export default SupportSchema;
