import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../../styles/SignUp.scss';
import { Link} from 'react-router-dom';
import logo from '../../Components/Assets/logo.png';
import { useSignUp } from './hooks/useSignUp';
import ReCAPTCHA from 'react-google-recaptcha';
import { getEnv } from '../../Helpers/Env';

const SignUp: React.FC = () => {
  const siteKey = getEnv("GOOGLE_RECAPTCHA") as string;
  const { initialValues, validationSchema, handleSubmit, recaptcha, isSubmitting, errorMessage } = useSignUp();

  return (
    <div className="signup-container">
      <div className="signup-card">
        <Link to="/">
          <img src={logo} alt="Logo" className="signup-logo" />
        </Link>
        <h2 className="signup-title">PERSONAL DETAILS</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, values }) => (
            <Form noValidate autoComplete="off" className="signup-form">
              <Field
                type="text"
                name="email_address"
                placeholder="EMAIL"
                className="signup-input"
              />
              <ErrorMessage name="email_address" component="div" className="ca-error-message" />

              <Field
                type="password"
                name="password"
                placeholder="PASSWORD"
                className="signup-input"
              />
              <ErrorMessage name="password" component="div" className="ca-error-message" />

              <Field
                type="text"
                name="first_name"
                placeholder="FIRST NAME"
                className="signup-input"
              />
              <ErrorMessage name="first_name" component="div" className="ca-error-message" />

              <Field
                type="text"
                name="last_name"
                placeholder="LAST NAME"
                className="signup-input"
              />
              <ErrorMessage name="last_name" component="div" className="ca-error-message" />

              <div className="ca-terms-container">
                <Field
                  type="checkbox"
                  name="tnc_accepted"
                  className="ca-terms-checkbox"
                />
                <label htmlFor="tnc_accepted" className="ca-terms">
                  I HAVE UNDERSTOOD THE{' '}
                  <Link to="/terms-and-conditions" className="ca-terms">
                    TERMS AND CONDITIONS
                  </Link>.
                </label>
              </div>
              <ErrorMessage name="tnc_accepted" component="div" className="ca-error-message" />

              {errorMessage && <div className="ca-error-message">{errorMessage}</div>}

              <ReCAPTCHA
                ref={recaptcha}
                sitekey={siteKey}
                size="invisible"
              />

              <button type="submit" className="signup-button" disabled={isSubmitting}>
                CREATE ACCOUNT
              </button>
            </Form>
          )}
        </Formik>
        <a href="/log-in" className="su-back">
          Back To Login
        </a>
      </div>
    </div>
  );
};

export default SignUp;