import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import '../../../styles/Track.scss';
import { MdChevronRight } from 'react-icons/md';

interface TrackingUpdate {
  timestamp: string;
  status: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

interface DeliveryDetails {
  pickupLocation: {
    address: string;
    latitude: number;
    longitude: number;
  };
  dropoffLocation: {
    address: string;
    latitude: number;
    longitude: number;
  };
}

interface DriverDetails {
  name: string;
  contactNumber: string;
}

interface Product {
  product_id: number;
  name: string;
  quantity: number;
}

interface Order {
  orderId: string;
  items: Product[];
  totalAmount: number;
}

interface DeliveryStatus {
  currentStatus: string;
}

interface Delivery {
  orderId: string;
  deliveryDetails: DeliveryDetails;
  driverDetails: DriverDetails;
  trackingUpdates: TrackingUpdate[];
  deliveryStatus: DeliveryStatus;
}

const Track: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const [delivery, setDelivery] = useState<Delivery | null>(null);
  const [order, setOrder] = useState<Order | null>(null);
  const [showAllUpdates, setShowAllUpdates] = useState(false);

  useEffect(() => {
    fetch('/assets/delivery.json')
      .then((response) => response.json())
      .then((data: Delivery[]) => setDelivery(data.find(d => d.orderId === orderId) || null))
      .catch((error) => console.error('Error fetching delivery:', error));

    fetch('/assets/order.json')
      .then((response) => response.json())
      .then((data: Order[]) => {
        const matchingOrder = data.find((order) => order.orderId === orderId);
        if (matchingOrder) setOrder(matchingOrder);
      })
      .catch((error) => console.error('Error fetching order:', error));
  }, [orderId]);

  if (!delivery || !order) {
    return <div className="track-loading">Loading...</div>;
  }

  const displayedUpdates = showAllUpdates
    ? delivery.trackingUpdates
    : delivery.trackingUpdates.slice(0, 3);

  return (
    <div className="track-page">
    <IoArrowBackCircle 
      className="track-back-button" size={36}
      onClick={() => navigate('/your-orders')} 
    />

      <div className="track-header">
        <div className="track-title">Track Order</div>
        <div className="order-id">{delivery.orderId}</div>
      </div>

      <div className="delivery-details">
        <div className="section-title">Delivery Details</div>
        <div className="text">Pickup Location: {delivery.deliveryDetails.pickupLocation.address}</div>
        <div className="text">Dropoff Location: {delivery.deliveryDetails.dropoffLocation.address}</div>
        <div className="delivery-status"><i><span>Status:</span> {delivery.deliveryStatus.currentStatus}</i></div>
      </div>

      <div className="driver-info">
        <div className="section-title">Carrier</div>
        <div className="text">Delivery Driver: {delivery.driverDetails.name}</div>
        <div className="text">
          Contact: <a href={`tel:${delivery.driverDetails.contactNumber}`}>{delivery.driverDetails.contactNumber}</a>
        </div>
      </div>

      <div className="tracking-timeline card">
        {displayedUpdates.map((update, index) => (
          <div key={index} className="timeline-entry">
            <div className="timeline-time">{new Date(update.timestamp).toLocaleString()}</div>
            <div className="timeline-status">{update.status}</div>
          </div>
        ))}
        {delivery.trackingUpdates.length > 3 && (
          <button 
            onClick={() => setShowAllUpdates(!showAllUpdates)} 
            className="show-more-btn"
          >
            {showAllUpdates ? 'Show Less' : 'Show More'}
          </button>
        )}
      </div>

      <div className="products-section">
        <div className="section-title">Products</div>
        {order.items.map((item, index) => (
          <div key={index} className="product-item">
            <div className="text">x{item.quantity} {item.name}</div>
            <Link to={`/review/${order.orderId}/${item.product_id}`} className="add-review-link">
              Add Review <MdChevronRight size={24} className="r-icon" />
            </Link>
            <div className="divider" />
          </div>
        ))}
        <div className="total-amount">Total Amount: {order.totalAmount.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default Track;
