import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import '../../styles/T_contactList.scss';
import AddContactModal from './T_addContact';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';

interface AddressInfo {
  longitude: string;
  latitude: string;
  street_address_1: string;
  street_address_2: string;
  state_municipality: string;
  city: string;
  zip_code: string;
  country: string;
  landmark?: string;
}

interface ContactInfo {
  contact_id: string;
  label: string;
  contact_name: string;
  contact_phone: string;
  contact_email_address: string;
  is_default_address: string;
  address_info: AddressInfo;
}

interface ContactListModalProps {
  contacts?: ContactInfo[];
  onClose: () => void;
  onSave: (selectedContact: ContactInfo) => void; 
}

const ContactListModal: React.FC<ContactListModalProps> = ({ onClose, onSave }) => {
  const [contacts, setContacts] = useState<ContactInfo[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [defaultContactId, setDefaultContactId] = useState<string | null>(null);

  useEffect(() => {

    const token = getTokenOrDefault();

    const fetchContacts = async () => {
      try {
  
        const response = await tryFetch({
          url: '/g/address',
          method: 'GET',
          token
        });
        
        if (response.is_success && Array.isArray(response.data)) {
          const formattedContacts: ContactInfo[] = response.data.map((item: any) => ({
            contact_id: item.contact_id,
            label: item.label,
            contact_name: item.contact_name,
            contact_phone: item.contact_phone,
            contact_email_address: item.contact_email_address,
            is_default_address: item.is_default_address,
            address_info: {
              longitude: item.address_info.longitude,
              latitude: item.address_info.latitude,
              street_address_1: item.address_info.street_address_1,
              street_address_2: item.address_info.street_address_2,
              state_municipality: item.address_info.state_municipality,
              city: item.address_info.city,
              country: item.address_info.country,
              zip_code: item.address_info.zip_code,
            },
          }));
          setContacts(formattedContacts);
        } else {
          console.error('Error fetching contacts:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, []);

  
  const handleEditClick = (contact: ContactInfo) => {
    setSelectedContactId(contact.contact_id);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => setIsEditModalOpen(false);

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);

  const handleContactSelect = (contactId: string) => setSelectedContactId(contactId);

  const handleSetDefault = (contactId: string) => setDefaultContactId(contactId);

  const handleSave = () => {
    const selectedContact = contacts.find((contact) => contact.contact_id === selectedContactId);
    if (selectedContact) {
      onSave(selectedContact);
    }
    onClose();
  };

  return (
    <div className="cl-modal-overlay" onClick={onClose}>
      <div className="cl-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="cl-header-container">
          <h2 className="cl-modal-header">Select Contact List</h2>
          <button className="cl-add-address-button" onClick={toggleAddModal}>
            + Add Address
          </button>
        </div>
        {contacts.map((contact) => (
          <div key={contact.contact_id} className="cl-contact-item">
            <input
              type="radio"
              checked={selectedContactId === contact.contact_id}
              onChange={() => handleContactSelect(contact.contact_id)}
              className="cl-contact-radio"
            />
            <div className="cl-contact-details">
              <p className="cl-p"><strong>{contact.contact_name}</strong></p>
              <p className="cl-p">{contact.contact_phone}</p>
              <p className="cl-p">{contact.address_info.street_address_1}</p>
              <p className="cl-p">{contact.address_info.street_address_2}</p>
              <p className="cl-p">
                {contact.address_info.city}, {contact.address_info.state_municipality}, {contact.address_info.zip_code}
              </p>
              <div className="cl-default-toggle">
                <label className="cl-label">
                  <input
                    className="cl-input"
                    type="checkbox"
                    checked={defaultContactId === contact.contact_id}
                    onChange={() => handleSetDefault(contact.contact_id)}
                  />
                  <span className="cl-span">Set as Default</span>
                </label>
              </div>
            </div>
            <FaEdit className="cl-edit-icon" onClick={() => handleEditClick(contact)} />
          </div>
        ))}
        {isEditModalOpen && <AddContactModal onClose={closeEditModal} />}
        {isAddModalOpen && <AddContactModal onClose={toggleAddModal} />}
        <button className="cl-modal-save-button" onClick={handleSave}>
          Save
        </button>
        <button className="cl-modal-close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ContactListModal;
