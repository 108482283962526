import React, { useEffect, useState } from 'react';
import '../../styles/PrivacyPolicy.scss';

interface ContentItem {
  title: string;
  description: string;
  content: string[];
}

interface PrivacyPolicyData {
  id: number;
  last_modified: string;
  subject: string;
  header: string;
  description: string;
  contents: ContentItem[];
}

const PrivacyPolicy = () => {
  const [privacyData, setPrivacyData] = useState<PrivacyPolicyData | null>(null);

  useEffect(() => {
    fetch('assets/privacy.json')
      .then((response) => response.json())
      .then((data) => setPrivacyData(data))
      .catch((error) => console.error('Error fetching privacy data:', error));
  }, []);

  if (!privacyData) {
    return <div>Loading...</div>;
  }

  const formatContent = (content: string) => {
    const [boldText, ...rest] = content.split(':');
    return (
      <div className="formatted-content">
      <strong>{boldText}:</strong>
      <span>{rest.join(': ').trim()}</span>
    </div>
    );
  };

  return (
    <div className="privacy-policy">
      <header className="privacy-header">
        <h1>{privacyData.subject}</h1>
        <p className="last-modified">Last Modified: {privacyData.last_modified}</p>
      </header>
      <section className="privacy-intro">
        <h2>{privacyData.header}</h2>
        <p>{privacyData.description}</p>
      </section>
      {privacyData.contents.map((item, index) => (
        <section key={index} className="privacy-section">
          <h3>{item.title}</h3>
          <p>{item.description}</p>
          {item.content.length > 0 && (
            <ul>
              {item.content.map((point, idx) => (
                 <li key={idx} className="content-item">
                 <span className="bullet">•</span>
                 {formatContent(point)}
               </li>
              ))}
            </ul>
          )}
        </section>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
