import React from 'react';
import '../../styles/Verify.scss';
import { useNavigate } from 'react-router-dom';

const Verify = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/log-in'); 
  };

  return (
    <div className="verify-container">
      <h1 className="verify-heading">Verify Your Email Address</h1>
      <p className="verify-text">
        We have sent an e-mail to your email address for verification. Please contact us if you do not receive it within a few minutes.
      </p>
      <button className="verify-button" onClick={handleLoginRedirect}>
        Go to Login
      </button>
    </div>
  );
};

export default Verify;
