import React, { createContext, useContext } from 'react'

export type UserType = {
    id: string,
    email_address: string,
    phone_number: string|null,
    first_name: string,
    last_name: string,
    image_url: string
}

export type SessionType = {
    isAuthenticated: boolean,
    user: UserType | null
}


interface IUserSessionType {
    session: SessionType,
    setSession: Function
}

export const SessionContext = createContext<IUserSessionType>({
    session: {
        isAuthenticated: false,
        user: null
    },
    setSession: () => {}
});

export default function SessionContextProvider(props: any) {
    const { value, children } = props;
    const { session, setSession } = value;

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    );
}

export const useSessionContext = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error(
            "useSessionContext must be unsed within a SessionContextProvider"
        );
    };
    return context;
}

