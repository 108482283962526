import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageLoading from "Pages/layout/PageLoading";
import { TryVerify } from "./actions";

const Verify = () => {
    const navigate = useNavigate();
    const { token } = useParams<{ token: string }>();

    const verify = async () => {
        if (!token) {
            console.error("Token not available.");
            navigate("/log-in");
            return;
        }

        try {
            const result = await TryVerify(token);
            if (result.is_success) {
                navigate("/log-in");
            } else {
                console.error("Verification failed:", result.error);
                navigate("/log-in");
            }
        } catch (error) {
            console.error("Verification error:", error);
            navigate("/log-in");
        }
    };

    useEffect(() => {
        verify();
    }, []); 

    return <PageLoading />;
};

export default Verify;
