import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import Merchant from '../Pages/private/merchant/Merchant';
import Product from '../Pages/private/merchant/Product';
import ReturnRefundPolicy from '../Pages/burger/ReturnRefund';
import PrivacyPolicy from '../Pages/burger/PrivacyPolicy';
import Eula from '../Pages/burger/Eula';
import Home from 'Pages/public/home/Home';
import FAQsPage from '../Pages/burger/FAQs';
import Support from '../Pages/burger/Support';
import TermsPage from '../Pages/burger/Terms&Conditions';
import PublicLayout from 'Pages/layout/PublicLayout';

const PublicRoute = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route path="/" element={<PublicLayout />}>
                    <Route index element={<Home />} />
                    <Route path="/merchant/:id" element={<Merchant />} />
                    <Route path="/merchant/:id/product/:spId" element={<Product />} />
                    <Route path="/faqs" element={<FAQsPage />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/terms-and-conditions" element={<TermsPage />} />
                    <Route path="/end-user-license-agreement" element={<Eula />} />
                    <Route path="/return-refund-policy" element={<ReturnRefundPolicy />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Route>
            </Routes>
        </React.Fragment>
    )
}

export default PublicRoute