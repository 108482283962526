import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { IoArrowBackCircle } from 'react-icons/io5';
import MediaUploader from '../../../Services/Media';
import '../../../styles/Review.scss';

interface Product {
  product_id: number;
  name: string;
  images: string[];
  variant: {
    size: string;
    color: string;
  };
}

interface Customer {
  customerId: string;
  name: string;
  last_name: string;
}

interface Order {
  orderId: string;
  customer: Customer;
  items: Product[];
}

const Review: React.FC = () => {
  const { orderId, productId } = useParams<{ orderId: string; productId: string }>();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [message, setMessage] = useState('');
  const [imageUrl, setImageUrl] = useState<string | null>(null); 
  const [starsCount, setStarsCount] = useState(0);


  useEffect(() => {
    fetch('/assets/order.json')
      .then(response => response.json())
      .then((data: Order[]) => {
        const order = data.find(order => order.orderId === orderId);
        const parsedProductId = productId ? parseInt(productId) : NaN;
        const product = order?.items.find(item => item.product_id === parsedProductId);

        if (product) setProduct(product);
        if (order?.customer) setCustomer(order.customer);
      })

      
      .catch(error => console.error('Error fetching order data:', error));
  }, [orderId, productId]);

  const handleSubmit = () => {

    if (!product) {
      console.error('Product is not defined');
      return;
    }
    
    const reviewData = {
      review_id: Math.floor(Math.random() * 100000),
      user: {
        user_id: customer?.customerId ?? 'Unknown',
        name: customer?.name ?? 'Unknown',
        last_name: customer?.last_name ?? 'Unknown',
      },
      product: {
        product_id: product.product_id,
        product_name: product.name,
        variant: {
          size: product.variant.size,
          color: product.variant.color,
        },
      },
      message,
      stars_count: starsCount,
      image: imageUrl,
      date: new Date().toISOString(),
    };
  };

  const handleBackNavigation = () => {
    if (orderId) {
      navigate(`/track/${orderId}`);
    } else {
      console.error('orderId is undefined or invalid');
    }
  };

  return (
    <div className="review-page">
      <IoArrowBackCircle
        className="rev-back-button"
        onClick={handleBackNavigation}
        size={32}
      />
      <h2 className="title-text">Product Review</h2>
      <p className="order-id">ORDER ID: {orderId}</p>
      {product && (
        <div className="product-info">
          <img src={product.images[0]} alt={product.name} className="product-image" />
          <div>
            <p className="product-name">{product.name}</p>
            <p className="product-variant">{product.variant.size} | {product.variant.color}</p>
          </div>
        </div>
      )}

      <div className="stars-rating">
        {[1, 2, 3, 4, 5].map(star => (
          <span
            key={star}
            onClick={() => setStarsCount(star)}
            className="star-icon"
          >
            {starsCount >= star ? <FaStar color="#3d3d3d" size={33} /> : <FaRegStar color="#3d3d3d" size={33} />}
          </span>
        ))}
      </div>

      <textarea
        placeholder="Write your review here."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="review-message"
      />
      <MediaUploader onUploadSuccess={setImageUrl} />
      <button onClick={handleSubmit} className="submit-button">
        SUBMIT REVIEW
      </button>
    </div>
  );
};

export default Review;
