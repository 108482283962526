import CryptoJS from "crypto-js";

export interface ISign {
    secretKey: string,
    appKey: string,
    method: string,
    path: string,
    timestamp: string,
    nonce: string
}

export const generateSign = ({secretKey, appKey, method, path, timestamp, nonce}: ISign) => {
    const data = `${appKey}.${method}.${path}.${timestamp}.${nonce}_tgl`;
    const hash: any = CryptoJS.HmacSHA256(data, secretKey);
    const signature = CryptoJS.enc.Base64.stringify(hash);
    return signature;
}