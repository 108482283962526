import React, { useEffect, useState } from 'react';
import '../../styles/ReturnRefund.scss';

interface ContentItem {
  title: string;
  description: string;
  content: string[];
}

interface ReturnRefundData {
  id: number;
  last_modified: string;
  subject: string;
  header: string;
  description: string;
  contents: ContentItem[];
}

const ReturnRefund = () => {
  const [returnRefundData, setReturnRefundData] = useState<ReturnRefundData | null>(null);

  useEffect(() => {
    fetch('assets/return_refund.json')
      .then((response) => response.json())
      .then((data) => setReturnRefundData(data))
      .catch((error) => console.error('Error fetching Return and Refund data:', error));
  }, []);

  const formatContent = (content: string) => {
    const [boldText, ...rest] = content.split(':');
    return (
      <div className="formatted-content">
        <strong>{boldText}: </strong>
        <span dangerouslySetInnerHTML={{ __html: rest.join(': ').trim() }} />
      </div>
    );
  };

  const isNumberedContent = (content: string) => /^\d+\./.test(content);

  if (!returnRefundData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="return-refund">
      <header className="return-refund-header">
        <h1>{returnRefundData.subject}</h1>
        <p className="last-modified">Last Modified: {returnRefundData.last_modified}</p>
      </header>
      <section className="return-refund-intro">
        <h2>{returnRefundData.header}</h2>
        <p>{returnRefundData.description}</p>
      </section>
      {returnRefundData.contents.map((item, index) => (
        <section key={index} className="return-refund-section">
          <h3>{item.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: item.description }} />
          {item.content.length > 0 && (
            <ul>
              {item.content.map((point, idx) => (
                <li
                key={idx}
                className={isNumberedContent(point) ? 'content-item numbered' : 'content-item'}
              >
                {!isNumberedContent(point) && <span className="bullet">•</span>}
                {formatContent(point)}
              </li>
              ))}
            </ul>
          )}
        </section>
      ))}
    </div>
  );
};

export default ReturnRefund;
