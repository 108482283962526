import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../styles/Home.scss'

const BannerSection: React.FC = () => {
  const [carouselItems, setCarouselItems] = useState<string[]>([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch('/assets/banner.json');
        const data = await response.json();
        setCarouselItems(data.map((banner: { image_url: string }) => banner.image_url));
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <div className="banner">
      <div className="carousel">
        <Slider {...settings}>
          {carouselItems.map((image, index) => (
            <div key={index} className="carousel-item">
              <img src={image} alt={`Banner ${index + 1}`} className="carousel-image" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BannerSection;
