import React, { useEffect, useState } from 'react'

export const useLocation = () => {
    const [coordinates, setCoordinates] = useState<string | null>(null);

    useEffect(() => {
        get();
    }, []);

    const get = async () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setCoordinates(`${position.coords.latitude},${position.coords.longitude}`);
            })
        }
    }

    return { coordinates }
}
