import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { IoArrowBackCircle } from 'react-icons/io5';
import '../../../styles/Product.scss';
import { FavoritesContext } from '../../../Context/favoritesContext';
import T_addToCart from '../../../Components/modal/T_addToCart';
import { useCart, CartItem } from '../../../Context/CartContext';
import { IoClose } from "react-icons/io5";
import { tryFetch } from '../../../Helpers/FetchAPI';


type Media = {
  url: string;
  source: string;
};

type VariantAttribute = {
  attribute_name: string;
  attribute_options: string;
};

type VariantItem = {
  id: string;
  attribute_options: string;
  attribute_checksum: string;
  count: number;
  price: string;
  media_url: string;
};

type Product = {
  id: string;
  store_id: string;
  product_id: string;
  title: string;
  specification: string;
  product_type: string;
  product_description: string;
  price: string;
  count: number;
  is_track_quantity: boolean;
  status: string;
  media: Media[];
  variants: {
    attributes: VariantAttribute[];
    items: VariantItem[];
  };
};

type Merchant = {
  id: string;
  merchant_name: string;
  hero_url: string,
  logo_url?: string;
};

type Review = {
  rating: string;
  remarks: string;
  date: string;
  media: { url: string; source: string }[];
};

const ProductPage = () => {
  const { id, spId } = useParams<{ id: string; spId: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const { likedProducts, toggleLike } = useContext(FavoritesContext) || {};
  const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);
  const { addToCart } = useCart();

  const [product, setProduct] = useState<Product | null>(null);
  const [otherProducts, setOtherProducts] = useState<Product[]>([]);
  const [merchant, setMerchant] = useState<Merchant | null>(null);
  
  const [reviews, setReviews] = useState<Review[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});
  const [isGrid, setIsGrid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState<string | null>(null);
  
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [location]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await tryFetch({
          url: `/merchants/${id}/products/${spId}`,
          method: 'GET',
        });

        if (response.is_success && typeof response.data === 'object') {
          setProduct(response.data as Product);
        } else {
          console.error('Error fetching product data:', response.errors);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchProductData();
  }, [id, spId]);


  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await tryFetch({
          url: `/merchants/${id}/products/${spId}/reviews`,
          method: 'GET',
        });
  
        if (response.is_success && Array.isArray(response.data)) {
          const transformedReviews: Review[] = response.data.map((item: any) => ({
            rating: item.rating,
            remarks: item.remarks,
            date: item.created_at,
            media: item.media,
          }));
          setReviews(transformedReviews);
        } else {
          console.error('Error fetching reviews:', response.errors || 'Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
  
    fetchReviews();
  }, [id, spId]);

  useEffect(() => {
    if (product) {
      const fetchOtherProducts = async () => {
        try {
          const response = await tryFetch({
            url: `/merchants/${id}/products?store_id=${product.store_id}`,
            method: 'GET',
          });

          if (response.is_success && Array.isArray(response.data)) {
            const filteredProducts = response.data.filter(
              (p: Product) => p.id !== product.id
            );
            setOtherProducts(filteredProducts as Product[]);
          } else {
            console.error('Error fetching related products:', response.errors);
          }
        } catch (error) {
          console.error('Error fetching related products:', error);
        }
      };

      fetchOtherProducts();
    }
  }, [id, product]);

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const response = await tryFetch({ url: `/merchants/${id}`, method: 'GET' });
        if (response.is_success && typeof response.data === 'object') {
          setMerchant(response.data as Merchant);
        } else {
          console.error('Error fetching merchant data:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching merchant data:', error);
      }
    };

    fetchMerchantData();
  }, [id]);


  useEffect(() => {
    if (product && product.media.length > 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % product.media.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [product]);

  const handleToggleLike = () => {
    if (product && toggleLike) {
      toggleLike(product.id);
    }
  };

  const toggleDescription = () => setShowFullDescription(!showFullDescription);
  const toggleGrid = () => setIsGrid(!isGrid);

  const handleSwipe = (direction: 'left' | 'right') => {
    if (product) {
      if (direction === 'left') {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.media.length);
      } else {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex - 1 + product.media.length) % product.media.length
        );
      }
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const handleBackClick = () => {
    if (product && product.store_id) {
      navigate(`/merchant/${product.store_id}`);
    } else {
      navigate(-1);
    }
  };

  const handleImageLoad = (imageKey: string) => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [imageKey]: true,
    }));
  };

 
  if (!product) {
    return <div>Loading product details...</div>;
  }

  const handleAddToCartClick = () => {
    setIsVariantModalOpen(true);
  };

  const handleCloseVariantModal = () => {
    setIsVariantModalOpen(false);
  };

  const handleConfirmAddToCart = (item: CartItem) => {
    addToCart(item); 
    setIsVariantModalOpen(false);
  };

  const handleImageClick = (src: string) => {
    setModalImageSrc(src);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageSrc(null);
  };

  const descriptionWords = product.product_description.split(" ");
  const isLongDescription = descriptionWords.length > 20;
  const truncatedDescription = descriptionWords.slice(0, 20).join(" ");

  return (
    <div className="product-page">
      <div className="product-banner" {...swipeHandlers}>
      <IoArrowBackCircle
          size={36}
          className="back-arrow"
          onClick={handleBackClick}
        />
         <img
          src={product.media[currentImageIndex]?.url}
          alt={`Product ${currentImageIndex}`}
          className={`banner-image ${loadedImages[product.media[currentImageIndex]?.url] ? 'loaded' : ''}`}
          onLoad={() => handleImageLoad(product.media[currentImageIndex]?.url!)}
        />
        <div className="carousel-indicators">
          {product.media.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => setCurrentImageIndex(index)}
            ></span>
          ))}
      </div>
      </div>
      <div className="product-content">
      <div className="product-image-container">
        <img src={product.media[0]?.url} alt={product.title} className="product-image" />
      </div>

      <div className="product-info">
      <div className="product-header">
        <h1 className="product-name">{product.title}</h1>
          <button className="heart-icon" onClick={handleToggleLike}>
          {likedProducts?.[product.id] ? <FaHeart color="#832b99" size="22" /> : <FaRegHeart size="22"/>}
        </button>
        </div>
        <p className="product-price">{parseFloat(product.price).toFixed(2)}</p>
        <h3>Specifications</h3>
        <p className="product-spec">{product.specification}</p>
        <h3>Description</h3>
        <p className="product-des">
          {showFullDescription || !isLongDescription
            ? product.product_description
            : truncatedDescription}
          {isLongDescription && !showFullDescription && (
            <span className="show-more" onClick={toggleDescription}> {' '}...</span>
          )}
        </p>
        <div className="product-actions">
        <button className="add-to-cart" onClick={handleAddToCartClick}>Add to Cart</button>
        </div>
      </div>
    </div>
    {isVariantModalOpen && product.variants && (
        <T_addToCart
          productId={product.id}
          productName={product.title}
          productMedia={product.media}
          productVariants={product.variants}
          merchant={merchant || { id: '', merchant_name: '', hero_url: '' }}
          onClose={handleCloseVariantModal}
          confirmAddToCart={handleConfirmAddToCart}
        />
      )}
<div className="reviews-section">
<div className="reviews-header">
  <h2 className="reviews-title">Reviews</h2>
  {reviews.length > 2 && !isGrid && (
      <button onClick={toggleGrid} className="see-all-button">
        See All
      </button>
    )}
    {isGrid && (
      <button onClick={toggleGrid} className="see-all-button">
        Show Less
  </button>
  )}
  </div>
  {reviews.length === 0 ? (
          <p className="no-reviews">No Reviews Yet</p>
        ) : (
          <div className={`reviews-slider ${isGrid ? 'grid-view' : ''}`}>
           {reviews.map((review, index) => (
              <div key={index} className="review-card">
              <div className="review-header">
                <div className="review-stars">{'★'.repeat(Math.round(parseFloat(review.rating)))}</div>
                <p className="review-date">{new Date(review.date).toLocaleDateString()}</p>
              </div>
                <p className="review-message">{review.remarks}</p>
                <p className="review-user">User_1975</p>
                {/* <p className="review-user">
                  {review.user.name} {review.user.last_name}
                </p> */}
        {review.media.length > 0 && (
          <div className="review-image-container">
            {review.media.map((media, idx) => (
              <img
                key={idx}
                src={media.url}
                alt={`Review Media ${idx + 1}`}
                className="review-image"
                onClick={() => handleImageClick(media.url)}
              />
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
)}
  {isModalOpen && (
        <div className="modal" onClick={closeModal}>
           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
           <button className="modal-close-button" onClick={closeModal}><IoClose size ="28"/></button>
            <img src={modalImageSrc!} alt="Review" className="modal-image" />
          </div>
        </div>
      )}
</div>

<div className="suggestions-section">
        <h2>You Might Also Like</h2>
        <div className="suggestions-slider">
          {otherProducts.map((relatedProduct) => (
            <div key={relatedProduct.id} className="suggestion-card">
              <Link to={`/merchant/${product.store_id}/product/${relatedProduct.id}`}>
                <img
                  src={relatedProduct.media[0]?.url}
                  alt={relatedProduct.title}
                  className={loadedImages[`related-${relatedProduct.product_id}`] ? 'loaded' : ''}
                  onLoad={() => handleImageLoad(`related-${relatedProduct.product_id}`)}
                />
              </Link>
              <p className="suggestion-name">{relatedProduct.title}</p>
              <p className="suggestion-price">{parseFloat(relatedProduct.price).toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;