import React, { useContext } from 'react';
import '../../../styles/Profile.scss';
import { MdOutlineHistory } from 'react-icons/md';
import { TbLogout2 } from 'react-icons/tb';
import { useSessionContext } from 'Context/SessionContext';
import { logout } from 'Security/actions';
import { getTokenOrDefault } from 'Helpers/Storage';
import { useProfile } from '../hooks/useProfile';

const Profile = () => {
	const { session, setSession } = useSessionContext();

	const handleLogout = async () => {
		const token = getTokenOrDefault();
		const response = await logout(token);
		if(response.is_success) {
			setSession({
				isAuthenticated: false,
				user: null
			});
		}
	};

	return (
		<React.Fragment>
			{session.user !== null ? (
				<div className="profile-container">
					<h2 className="profile-title">Profile</h2>
					<div className="profile-icons">
						<div className="icon">
							<MdOutlineHistory className="icon-image" size="30" />
						</div>
					</div>
					<hr className="profile-divider" />
					<p className="profile-detail"><strong>Full Name:</strong> {session.user.first_name} {session.user.last_name}</p>
					<p className="profile-detail"><strong>Email:</strong> {session.user.email_address}</p>
					<hr className="profile-divider" />

					<button onClick={handleLogout} className="logout-button">
						<TbLogout2 className="logout-icon" /> Logout
					</button>
				</div>
			) : <p>Loading...</p>}
		</React.Fragment>

	);
};

export default Profile;