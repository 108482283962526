import React, { useContext, useState, useEffect } from 'react';
import { FavoritesContext } from '../../../Context/favoritesContext';
import { Link } from 'react-router-dom';
import { MdOutlineAddShoppingCart } from "react-icons/md";
import '../../../styles/Favorites.scss';
import { FaHeart, FaRegHeart } from "react-icons/fa";
import T_addToCart from '../../../Components/modal/T_addToCart';
import { useCart, CartItem } from '../../../Context/CartContext';

interface Media {
  mime_type: string;
  url: string;
  source: string; // Updated to include "source"
}

interface ProductVariant {
  attributes: { attribute_name: string; attribute_options: string }[];
  items: {
    id: string;
    attribute_options: string;
    attribute_checksum: string;
    count: number;
    price: string;
    media_url: string;
  }[];
}

interface Product {
  product_id: string;
  name: string;
  specification: string;
  product_description: string;
  media: Media[];
  variants: ProductVariant;
}

interface Merchant {
  id: string;
  merchant_name: string;
  description: string;
  category: string;
  hero_url: string;
  logo_url: string;
  status: string;
  featured?: Media[] | null;
  products: Product[];
}

const Favorites: React.FC = () => {
  const context = useContext(FavoritesContext);
  const { addToCart } = useCart();

  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [showAddToCart, setShowAddToCart] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant | null>(null);

  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const response = await fetch('/assets/data.json');
        const data = await response.json();
        setMerchants(data.merchants);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchMerchants();
  }, []);

  if (!context) {
    return <p>Error: Favorites context is not available.</p>;
  }

  const { likedProducts, toggleLike } = context;
  const likedProductIds = Object.keys(likedProducts).filter(
    (id) => likedProducts[parseInt(id)]
  );
  const likedItems: { product: Product; merchant: Merchant }[] = merchants.flatMap((merchant) =>
    merchant.products
      .filter((product: Product) =>
        likedProductIds.includes(product.product_id.toString())
      )
      .map((product) => ({ product, merchant }))
  );

  const handleAddToCartClick = (product: Product, merchant: Merchant) => {
    setSelectedProduct(product);
    setSelectedMerchant(merchant);
    setShowAddToCart(true);
  };

  const handleCloseAddToCart = () => {
    setShowAddToCart(false);
    setSelectedProduct(null);
  };

  return (
    <div className="favorites-page">
      <h1>Your Wishlist</h1>
      {likedItems.length > 0 ? (
        <div className="favorites-grid">
          {likedItems.map(({ product, merchant }) => (
            <div key={product.product_id} className="favorite-item">
              <Link to={`/product/${product.product_id}`}>
                <img src={product.media[0].url} alt={product.name} />
                <div className="product-info">
                  <p className="product-name">
                    {product.name.length > 13
                      ? `${product.name.substring(0, 13)}...`
                      : product.name}
                  </p>
                  <p className="product-price">
                    {parseFloat(product.variants.items[0].price).toFixed(2)}
                  </p>
                </div>
              </Link>
              <MdOutlineAddShoppingCart
                className="cart-icon"
                size="25"
                onClick={() => handleAddToCartClick(product, merchant)}
              />
              <button
                className="heart-icon"
                onClick={() => context.toggleLike(product.product_id)}
              >
                {likedProducts[product.product_id] ? (
                  <FaHeart color="#832b99" size="22" />
                ) : (
                  <FaRegHeart />
                )}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p>Create your wishlist – Like it and Gottit!</p>
      )}
      {showAddToCart && selectedProduct && selectedMerchant && (
        <T_addToCart
          productId={selectedProduct.product_id}
          productName={selectedProduct.name}
          productMedia={selectedProduct.media}
          productVariants={selectedProduct.variants}
          merchant={selectedMerchant}
          onClose={handleCloseAddToCart}
          confirmAddToCart={(cartItem) => {
            addToCart(cartItem);
            toggleLike(selectedProduct.product_id);
            handleCloseAddToCart();
          }}
        />
      )}
    </div>
  );
};

export default Favorites;
