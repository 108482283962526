import React, { useEffect, useState } from 'react';
import '../../styles/Eula.scss';

interface ContentItem {
  title: string;
  description: string;
  content: string[];
}

interface EulaData {
  id: number;
  last_modified: string;
  subject: string;
  header: string;
  description: string;
  contents: ContentItem[];
}

const Eula = () => {
  const [eulaData, setEulaData] = useState<EulaData | null>(null);

  useEffect(() => {
    fetch('assets/eula.json')
      .then((response) => response.json())
      .then((data) => setEulaData(data))
      .catch((error) => console.error('Error fetching EULA data:', error));
  }, []);

  if (!eulaData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="eula">
      <header className="eula-header">
        <h1>{eulaData.subject}</h1>
        <p className="last-modified">Last Modified: {eulaData.last_modified}</p>
      </header>
      <section className="eula-intro">
        <h2><strong>{eulaData.header}</strong></h2>
        <p>{eulaData.description}</p>
      </section>
      {eulaData.contents.map((item, index) => (
        <section key={index} className="eula-section">
          <h3><strong>{item.title}</strong></h3>
          <p>{item.description}</p>
          {item.content.length > 0 && (
            <ul>
              {item.content.map((point, idx) => (
                <li key={idx} className="content-item">
                <span className="bullet">•</span> {point}
                </li>
              ))}
            </ul>
          )}
        </section>
      ))}
    </div>
  );
};

export default Eula;
