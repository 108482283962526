import * as yup from 'yup';


export const SignInSchema = yup.object({
  email_address: yup.string().email("Invalid email").required("Email address is required"),
  password: yup.string().required("Password is required"),
  g_token: yup.string().nullable(),
  location: yup.string().nullable()
});


export const SignUpSchema = yup.object({
  email_address: yup.string().email("Invalid email").required("Required"),
  password: yup.string().min(6, "Password must be at least 6 characters").required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  tnc_accepted: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});
