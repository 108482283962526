import NotFound from 'Pages/layout/NotFound'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

const NotFoundRoute = () => {
    return (
        <Routes>
            <Route path="*" element={<NotFound/>} />
        </Routes>
    )
}

export default NotFoundRoute