import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface FavoritesContextType {
  likedProducts: { [key: string]: boolean };
  toggleLike: (productId: string) => void;
  favCount: number;
}

export const FavoritesContext = createContext<FavoritesContextType | undefined>(undefined);

const FavoritesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [likedProducts, setLikedProducts] = useState<{ [key: string]: boolean }>(() => {
    const savedFavorites = localStorage.getItem('likedProducts');
    return savedFavorites ? JSON.parse(savedFavorites) : {};
  });

  useEffect(() => {
    localStorage.setItem('likedProducts', JSON.stringify(likedProducts));
  }, [likedProducts]);

  const toggleLike = (productId: string) => {
    setLikedProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const favCount = Object.values(likedProducts).filter((liked) => liked).length;

  return (
    <FavoritesContext.Provider value={{ likedProducts, toggleLike, favCount }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export default FavoritesProvider;
