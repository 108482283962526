import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignUpSchema } from '../schema/security';
import ReCAPTCHA from 'react-google-recaptcha';
import { tryFetch } from '../../../Helpers/FetchAPI';
import { useLocation } from '../../hooks/useLocation';

export const useSignUp = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const recaptcha = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();
  const { coordinates } = useLocation();

  const initialValues = {
    email_address: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_country_code:"",
    phone_number:"",
    tnc_accepted: false,
    g_token: '',
    location: '',
  };

  const handleSubmit = async (values: typeof initialValues) => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      values.location = coordinates || '';

      const token = await recaptcha.current?.executeAsync();
      if (!token) {
        throw new Error('Recaptcha verification failed. Please try again.');
      }
      values.g_token = token;

      const response = await tryFetch({
        url: '/sec/register',
        method: 'POST',
        payload: values,
      });

      if (response.is_success) {
        navigate('/verify');
      } else {
        console.error('Registration Failed:', response.errors);
        setErrorMessage(response.errors?.[0] || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('API Error:', error);
      setErrorMessage('An error occurred during registration. Please try again.');
    } finally {
      recaptcha.current?.reset();
      setIsSubmitting(false);
    }
  };

  return { initialValues, validationSchema: SignUpSchema, handleSubmit, recaptcha, isSubmitting, errorMessage };
};
