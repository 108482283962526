import React, { useState, useEffect } from "react";
import { Marker,  Circle } from "@react-google-maps/api";
import Map from "./Map";

interface DragableMapProps {
  onLocationChange: (details: {
    coordinates: { lat: number; lng: number };
    city: string;
    municipality: string;
    streetAddress1: string;
    streetAddress2: string;
  }) => void;
}

const DragableMap: React.FC<DragableMapProps> = ({ onLocationChange }) => {
  const [markerPosition, setMarkerPosition] = useState({lat: 14.5995, lng: 120.9842 });
  const [currentPosition, setCurrentPosition] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    if (!currentPosition && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userPosition = { lat: latitude, lng: longitude };
          setCurrentPosition(userPosition);
          setMarkerPosition(userPosition);
          fetchAddressDetails(latitude, longitude);
        },
        (error) => {
          console.error("Error fetching user's location:", error);
        }
      );
    }
  }, [currentPosition]);

  const fetchAddressDetails = async (lat: number, lng: number) => {
    const API_KEY = process.env.REACT_APP_GM || "";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.results?.length > 0) {
        const address = data.results[0].address_components;

        const getAddressComponent = (type: string) =>
          address.find((comp: any) => comp.types.includes(type))?.long_name || "";

        const details = {
          coordinates: { lat, lng },
          city: getAddressComponent("locality"),
          municipality: getAddressComponent("administrative_area_level_2"),
          streetAddress1: getAddressComponent("route"),
          streetAddress2: getAddressComponent("sublocality"),
        };

        onLocationChange(details);
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

   const handleDragEnd = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setMarkerPosition({ lat, lng });
      fetchAddressDetails(lat, lng);
    }
  };

  return (
    <Map center={currentPosition || markerPosition}>
    {currentPosition && (
      <Circle
        center={currentPosition}
        radius={50}
        options={{
          fillColor: "#a239c0",
          fillOpacity: 0.35,
          strokeColor: "#832b99",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        }}
      />
    )}
    <Marker position={markerPosition} draggable onDragEnd={handleDragEnd}
    icon={
      window.google
        ? {
            url: "https://gottit-media.b-cdn.net/sandbox/pin.png",
            scaledSize: new window.google.maps.Size(40, 40), 
          }
        : undefined
    }/>
  </Map>
);
};

export default DragableMap;

