import React from 'react';
import { useDropzone } from 'react-dropzone';

interface MediaUploaderProps {
  onUploadSuccess: (url: string) => void;
}

const MediaUploader: React.FC<MediaUploaderProps> = ({ onUploadSuccess }) => {
  const handleDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file.type.startsWith("image/")) {
      alert("Only image files are allowed.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);


      const response = await fetch(
        `https://storage.bunnycdn.com/gottit-media/${file.name}`,
        {
          method: "PUT",
          headers: {
            "AccessKey": process.env.REACT_APP_CB!,
            "Content-Type": file.type,
          },
          body: file,
        }
      );

      if (response.ok) {
        const fileUrl = `https://gottit-media.b-cdn.net/${file.name}`;
        onUploadSuccess(fileUrl);
      } else {
        alert("Failed to upload the image. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading the image. Please try again.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: { "image/*": [] },
  });

  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      <p>Drag & drop an image here, or click to select a file</p>
    </div>
  );
};

export default MediaUploader;
