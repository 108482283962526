import React, { useEffect, useState } from 'react';
import '../../styles/loadingPage.scss';

const PageLoading = () => {
    const [logoUrl, setLogoUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const response = await fetch('/assets/asset.json');
        const assets = await response.json();
        const logo = assets.find((item: any) => item.type === 'logo');
        setLogoUrl(logo.image_url);
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);

  if (!logoUrl) return null;

  return (
    <div className="loading-container">
      <img src={logoUrl} alt="Logo" className="loading-logo" />
    </div>
  );
};

export default PageLoading

