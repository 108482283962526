import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ImAttachment } from 'react-icons/im';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../../styles/Support.scss';
import SupportSchema from '../../Schema/SupportSchema';
import { useDropzone } from 'react-dropzone';

interface FormValues {
  fullName: string;
  email: string;
  contactNumber: string;
  subject: string;
  message: string;
  attachment: File | null;
}

const Support: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [attachment, setAttachment] = useState<File | null>(null);

  const handleSubmit = (values: FormValues, { setSubmitting, resetForm }: any) => {
    const formData = new FormData();
    formData.append('fullName', values.fullName);
    formData.append('email', values.email);
    formData.append('contactNumber', values.contactNumber);
    formData.append('subject', values.subject);
    formData.append('message', values.message);
    if (values.attachment) {
      formData.append('attachment', values.attachment);
    }

    setShowPopup(true);
    setSubmitting(false);
    resetForm();
    setAttachment(null);
  };

  const closePopup = () => setShowPopup(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setAttachment(acceptedFiles[0]);
    },
    multiple: false,
  });

  return (
    <div className="support-page">
      <div className="support-layout">
        <h1>Is there anything we can help you with?</h1>
        <div className="support-container">
          <Formik
            initialValues={{
              fullName: '',
              email: '',
              contactNumber: '',
              subject: '',
              message: '',
              attachment: null as File | null,
            }}
            validationSchema={SupportSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form className="support-form">
                <div className="form-group">
                  <label htmlFor="fullName">Full Name:</label>
                  <Field type="text" id="fullName" name="fullName" />
                  <ErrorMessage name="fullName" component="p" className="error-alert" />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <Field type="email" id="email" name="email" />
                  <ErrorMessage name="email" component="p" className="error-alert" />
                </div>
                <div className="form-group">
                  <label htmlFor="contactNumber">Contact Number:</label>
                  <Field type="tel" id="contactNumber" name="contactNumber" />
                  <ErrorMessage name="contactNumber" component="p" className="error-alert" />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject:</label>
                  <Field type="text" id="subject" name="subject" />
                  <ErrorMessage name="subject" component="p" className="error-alert" />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Please describe the issue below:</label>
                  <Field as="textarea" id="message" name="message" rows={5} />
                  <ErrorMessage name="message" component="p" className="error-alert" />
                </div>
                <div className="form-group attachment-group">
                  <label htmlFor="attachment">
                    Attachment: <ImAttachment className="attachment-icon" />
                  </label>
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the file here...</p>
                    ) : (
                      <p>Drag & drop a file here, or click to select a file</p>
                    )}
                    {attachment && <p className="file-name">{attachment.name}</p>}
                  </div>
                </div>
                <button type="submit" className="submit-button">Send</button>
              </Form>
            )}
          </Formik>

          {showPopup && (
            <div className="popup-overlay">
              <div className="popup">
                <div className="popup-content">
                  <p><strong>Thanks for reaching out!</strong></p>
                  <p>We've received your message and will prioritize your concern. Please expect our dedicated customer care affiliate to get back to you within 24 hours.</p>
                  <Link to="/" className="home-link">
                    <button onClick={closePopup} className="home-button">Go to Home Page</button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Support;
