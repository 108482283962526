import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../../styles/Checkout.scss';
import AddContactModal from '../../../Components/modal/T_addContact';
import ContactListModal from '../../../Components/modal/T_contactList';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';

interface AddressInfo {
  longitude: string;
  latitude: string;
  street_address_1: string;
  street_address_2: string;
  state_municipality: string;
  city: string;
  country: string;
  zip_code: string;
  landmark?: string;
}

interface ContactInfo {
  contact_id: string;
  label: string;
  contact_name: string;
  contact_phone: string;
  contact_email_address: string;
  is_default_address: string;
  address_info: AddressInfo;
}

interface Product {
  id: number;
  name: string;
  variant: { size?: string; color?: string };
  quantity: number;
  price: number;
  subTotal: string;
}

interface Merchant {
  merchantId: number;
  merchantName: string;
  products: Product[];
}

interface CheckoutData {
  checkoutData: Merchant[];
  totalAmount: string;
}

const Checkout: React.FC = () => {
  const [showContactListModal, setShowContactListModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [contacts, setContacts] = useState<ContactInfo[]>([]);
  const [selectedContact, setSelectedContact] = useState<ContactInfo | null>(null);
  const [shippingFee, setShippingFee] = useState<number | null>(null);
  const location = useLocation();
  const { checkoutData, totalAmount } = location.state || {};

  const serviceFee = ((parseFloat(totalAmount || '0') + (shippingFee || 0)) * 0.1).toFixed(2);

  const grandTotal = (
    parseFloat(totalAmount || '0') +
    (shippingFee !== null ? shippingFee : 0) +
    parseFloat(serviceFee)
  ).toFixed(2);

  useEffect(() => {
    const fetchContacts = async () => {

      const token = getTokenOrDefault();
      try {
        const response = await tryFetch({ url: '/g/address', method: 'GET', token });
        if (response.is_success && Array.isArray(response.data)) {
          const contacts = response.data as ContactInfo[];
          setContacts(contacts);
          const defaultContact = contacts.find(contact => contact.is_default_address === '1');
          if (defaultContact) {
            setSelectedContact(defaultContact);
          }
        } else {
          console.error('Failed to fetch contacts:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, []);

  useEffect(() => {
    const fetchShippingFee = async () => {
      if (!selectedContact) return;

      const stops = [
        {
          address: `${selectedContact.address_info.street_address_1}, ${selectedContact.address_info.city}`,
          coordinates: {
            lat: parseFloat(selectedContact.address_info.latitude),
            lng: parseFloat(selectedContact.address_info.longitude),
          },
        },
      ];

      try {
        const token = getTokenOrDefault();
        
        const response = await tryFetch({
          url: '/g/calculate-shipping',
          method: 'POST',
          token,
          body: JSON.stringify({ stops }),
        });
  
        if (typeof response === 'object' && response.is_success && response.data) {
          const data = response.data as {
            data: {
              priceBreakdown: { total: string };
            };
          };
          setShippingFee(parseFloat(data.data.priceBreakdown.total));
        } else {
          console.error('Failed to calculate shipping:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching shipping fee:', error);
      }
    };
  
    fetchShippingFee();
  }, [selectedContact]);
  
  const handleSaveContact = (contact: ContactInfo) => {
    setSelectedContact(contact);

    const updatedContacts = contacts.map((c) => ({
      ...c,
      label: c.contact_id === contact.contact_id ? 'default' : '',
    }));
    setContacts(updatedContacts);
  };

  const truncateText = (text: string, maxLength: number) =>
    text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <div className="checkout-page">
      <h1 className="checkout-header">CHECKOUT</h1>

      <div className="checkout-container">
        <div className="left-section">
          <div className="contact-info">
            <h2 className="contact-text">Contact Information</h2>
            {selectedContact ? (
              <div className="contact-details">
                <p>{selectedContact.contact_name}</p>
                <p>{selectedContact.contact_phone}</p>
                <p>
                  {selectedContact.address_info.street_address_1}, {selectedContact.address_info.street_address_2},
                  {selectedContact.address_info.state_municipality}, {selectedContact.address_info.city},{' '}
                  {selectedContact.address_info.zip_code}
                </p>
                {selectedContact.address_info.landmark && (
                  <p>Other details: {selectedContact.address_info.landmark}</p>
                )}
                <button className="change-contact-button" onClick={() => setShowContactListModal(true)}>
                  Change Contact Info
                </button>
              </div>
            ) : (
              <button className="add-address-button" onClick={() => setShowAddModal(true)}>
                + Add Address
              </button>
            )}
          </div>

          <textarea
            className="note-input"
            placeholder="Add a note (optional)"
          ></textarea>
        </div>

        <div className="right-section">
          <div className="order-summary">
            <h2>Order Summary</h2>
            <div className="order-items">
              {checkoutData?.map((merchant: Merchant) => (
                <div key={merchant.merchantId}>
                  <h3>{merchant.merchantName}</h3>
                  {merchant.products.map((product: Product, index: number) => (
                    <div key={index} className="order-item">
                      <span className="product-info">
                        {truncateText(product.name, 24)} (x{product.quantity})
                      </span>
                      <span className="product-subtotal">{product.subTotal}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="order-totals">
              <div className="order-total-row">
                <span>Subtotal:</span>
                <span className="total-amount">{totalAmount}</span>
              </div>
              <div className="order-total-row">
                <span>Shipping:</span>
                <span className="total-amount">
                  {shippingFee !== null ? shippingFee.toFixed(2) : 'Calculating...'}
                </span>
              </div>
              <div className="order-total-row">
                <span>Service Fee (10%):</span>
                <span className="total-amount">{serviceFee}</span>
              </div>
              <div className="order-total-row grand-total">
                <span>Total:</span>
                <span className="total-amount">{grandTotal}</span>
              </div>
            </div>
          </div>

          <div className="payment-method">
            <h2>Payment Method</h2>
            <label>
              <input type="radio" name="payment" /> Credit/Debit Card
            </label>
            <label>
              <input type="radio" name="payment" /> E-wallet
            </label>
            <label>
              <input type="radio" name="payment" disabled /> Pay On Delivery
            </label>
          </div>
        </div>
      </div>

      <div className="checkout-footer">
        <div className="total-info">
          <p className="total-text">
            Total (No. of items:{' '}
            {checkoutData?.reduce((sum: number, merchant: Merchant) => sum + merchant.products.length, 0)})
          </p>
          <p className="total-amount">{grandTotal}</p>
        </div>
        <button className="place-order-button">Place Order</button>
      </div>

      {showContactListModal && (
        <ContactListModal
          contacts={contacts}
          onClose={() => setShowContactListModal(false)}
          onSave={handleSaveContact}
        />
      )}

      {showAddModal && <AddContactModal onClose={() => setShowAddModal(false)} />}
    </div>
  );
};

export default Checkout;
