import { getEnv } from "./Env";
import { generateSign, ISign } from "./Signature";
import { nonce } from "./String";

export interface IParams {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    token?: string;
    body?: string;
    refresh_token?: string;
    payload?: object | object[];
    formData?: FormData;
    query?: string;
}

export interface IResponse {
    error: string;
    role: any;
    id: any;
    permissions: any;
    data: object | object[] | null | string;
    errors: string[] | null;
    is_success: boolean;
    status_code: number;
}

export interface RolePermission {
    id: string;
    label: string;
    permissions: Record<string, boolean>;
}

export interface RegistrationResponse {
    verification_token?: string;
    [key: string]: any;
  }

export const tryFetch = async (params: IParams): Promise<IResponse> => {
    try {
        const apiPath = "/market/v1";
        // const payload = typeof(params.payload) !== "undefined" ? params.payload : {};
        const payload = params.payload ?? {};

        const sign: ISign = {
            secretKey: getEnv("SIGNATURE_SECRET") as string,
            appKey: getEnv("APP_KEY") as string,
            method: params.method.toUpperCase(),
            path: apiPath + params.url,
            timestamp: Date.now().toString(),
            nonce: nonce()
        }
        const signature = generateSign(sign);

    
        const headers: any = {
            "Content-Type": "application/json",
            "X-AppKey": getEnv("APP_KEY"),
            "X-Timestamp": sign.timestamp,
            "X-Nonce": sign.nonce,
            "X-Signature": signature
        };

        if (params.token) {
            headers["Authorization"] = `Bearer ${params.token}`;
        }

        const method = params.method || "POST";
        const endpoint = getEnv("API_ENDPOINT");
        
        let url = endpoint + sign.path;
        if (params.query) {
            url = url + params.query;
        }


        const response = await fetch(url, {
            method,
            headers,
            credentials: "include",
            body: (method === "POST" && Object.keys(payload).length) ? JSON.stringify(payload) : undefined
        });

        if (!response.ok) {
            console.error("Response Error:", response.status, response.statusText);
            throw new Error(`HTTP ${response.status}: ${response.statusText}`);
          }
      
          return await response.json();
        } catch (error) {
          console.error("Fetch error:", error);
          throw error;
        }
      };