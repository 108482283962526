import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import '../../styles/T_addToCart.scss';
import { TbMoodEmpty } from 'react-icons/tb';
import { CartItem } from '../../Context/CartContext';

type VariantItem = {
  id: string;
  attribute_options: string;
  attribute_checksum: string;
  count: number;
  price: string;
  media_url: string;
};

type Attribute = {
  attribute_name: string;
  attribute_options: string;
};

type Media = {
  source: string;
  url: string;
};

type Merchant = {
  id: string;
  merchant_name: string;
  hero_url: string;
  logo_url?: string;
};

type T_addToCartProps = {
  productId: string;
  productName: string;
  productDescription?: string;
  productMedia: Media[];
  productVariants: {
    attributes: Attribute[];
    items: VariantItem[];
  };
  merchant: Merchant;
  onClose: () => void;
  confirmAddToCart: (item: CartItem) => void;
};

const T_addToCart: React.FC<T_addToCartProps> = ({
  productId,
  productName,
  productMedia,
  productVariants,
  merchant,
  onClose,
  confirmAddToCart,
}) => {
  const [selectedAttributes, setSelectedAttributes] = useState<Record<string, string>>({});
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOutOfStockModal, setShowOutOfStockModal] = useState(false);

  const handleAttributeSelect = (attributeName: string, value: string) => {
    setSelectedAttributes((prev) => ({
      ...prev,
      [attributeName]: value,
    }));
    setErrorMessage('');
    setQuantity(1);
  };

  const handleQuantityChange = (delta: number) => {
    const stockCount = selectedVariant?.count ?? 0;
    setQuantity((prev) => Math.max(1, Math.min(prev + delta, stockCount)));
  };

  const sortedAttributes = Object.keys(selectedAttributes)
    .sort()
    .map((key) => selectedAttributes[key])
    .join(',');

  const selectedVariant = productVariants.items.find(
    (item) => item.attribute_options === sortedAttributes
  );

  const price = selectedVariant?.price || '0.00';

  const handleAddToCart = () => {
    if (selectedVariant?.count === 0) {
      setShowOutOfStockModal(true);
      return;
    }
    if (Object.keys(selectedAttributes).length !== productVariants.attributes.length) {
      setErrorMessage('Please select all variant options.');
      return;
    }

    const cartItem: CartItem = {
      productId,
      productName,
      productMedia: [selectedVariant?.media_url || productMedia[0]?.url],
      price: parseFloat(price),
      quantity,
      availableStock: selectedVariant?.count ?? 0,
      variantDetails: selectedAttributes,
      merchant,
    };
    confirmAddToCart(cartItem);
    onClose();
  };

  const isOptionDisabled = (attributeName: string, value: string) => {
    const updatedAttributes = { ...selectedAttributes, [attributeName]: value };
    const filteredOptions = Object.keys(updatedAttributes)
      .sort()
      .map((key) => updatedAttributes[key])
      .join(',');
    return !productVariants.items.some((item) =>
      item.attribute_options.startsWith(filteredOptions)
    );
  };

  return (
    <div className="variant-card-overlay">
      <div className="variant-card">
        <IoClose className="close-icon" onClick={onClose} size="30" />
        <img
          src={selectedVariant?.media_url || productMedia[0]?.url}
          alt={productName}
          className="variant-card-image"
        />
        <h3>{productName}</h3>
        <p className="variant-price">{parseFloat(price).toFixed(2)}</p>
        <p className="variant-stock">Stock: {selectedVariant?.count ?? 0}</p>

        {productVariants.attributes.map((attribute) => (
          <div key={attribute.attribute_name}>
            <h4>{attribute.attribute_name}</h4>
            <div className="variant-list">
              {attribute.attribute_options.split(',').map((option) => (
                <div
                  key={option}
                  className={`variant-option ${
                    selectedAttributes[attribute.attribute_name] === option ? 'selected' : ''
                  } ${isOptionDisabled(attribute.attribute_name, option) ? 'disabled' : ''}`}
                  onClick={() =>
                    !isOptionDisabled(attribute.attribute_name, option) &&
                    handleAttributeSelect(attribute.attribute_name, option)
                  }
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        ))}

        <h4>Qty.</h4>
        <div className="quantity-control">
          <button onClick={() => handleQuantityChange(-1)}>-</button>
          <span>{quantity}</span>
          <button onClick={() => handleQuantityChange(1)}>+</button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button className="confirm-add-to-cart" onClick={handleAddToCart}>
          Add to Cart
        </button>
      </div>
      {showOutOfStockModal && (
        <div className="out-of-stock-modal-overlay">
          <div className="out-of-stock-modal">
            <TbMoodEmpty className="out-of-stock-icon" size={48} />
            <p className="os-text">Oops. This Product is out of Stock!</p>
            <button className="os-close-btn" onClick={() => setShowOutOfStockModal(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default T_addToCart;
