import React, { useState, useEffect } from 'react';
import { useCart } from '../../../Context/CartContext';
import { IoClose } from 'react-icons/io5';
import '../../../styles/Cart.scss';
import { useNavigate } from 'react-router-dom';

interface Merchant {
  id: string;
  merchant_name: string;
  hero_url: string;
}

interface SelectedItem {
  productId: string;
  variantDetails: { size?: string; color?: string };
}

const CartPage: React.FC = () => {
  const { cartItems, updateQuantity, getTotalAmount, removeItem } = useCart();
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [selectedMerchants, setSelectedMerchants] = useState<string[]>([]);
  const navigate = useNavigate();


  useEffect(() => {
    const initialSelectedItems = cartItems.map(item => ({
      productId: item.productId,
      variantDetails: item.variantDetails
    }));
    setSelectedItems(initialSelectedItems);

    const initialSelectedMerchants = Array.from(
      new Set(
        cartItems
          .filter((item) =>
            initialSelectedItems.some(
              (selected) =>
                selected.productId === item.productId &&
                selected.variantDetails.size === item.variantDetails.size &&
                selected.variantDetails.color === item.variantDetails.color
            )
          )
          .map((item) => item.merchant.id)
      )
    );
    setSelectedMerchants(initialSelectedMerchants);
  }, [cartItems]);

  const groupedCartItems = cartItems.reduce((acc, item) => {
    const { merchant } = item;
    if (!acc[merchant.id]) {
      acc[merchant.id] = { merchant, items: [] };
    }
    acc[merchant.id].items.push(item);
    return acc;
  }, {} as Record<string, { merchant: Merchant; items: typeof cartItems }>);
  
  const handleRemoveItem = (productId: string, variantDetails: { size?: string; color?: string }) => {
    const indexToRemove = cartItems.findIndex(
      (item) =>
        item.productId === productId &&
        item.variantDetails.size === variantDetails.size &&
        item.variantDetails.color === variantDetails.color
    );
  
    if (indexToRemove !== -1) {
      removeItem(indexToRemove);
    }
  };

  const navigateToMerchant = (merchantId: string) => {
    navigate(`/merchant/${merchantId}`);
  };

  const toggleSelectItem = (productId: string, variantDetails: { size?: string; color?: string }) => {
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some(
        (item) =>
          item.productId === productId &&
          item.variantDetails.size === variantDetails.size &&
          item.variantDetails.color === variantDetails.color
      );

      const updatedSelected = isSelected
        ? prevSelected.filter(
            (item) =>
              !(item.productId === productId &&
                item.variantDetails.size === variantDetails.size &&
                item.variantDetails.color === variantDetails.color)
          )
        : [...prevSelected, { productId, variantDetails }];

      const selectedMerchants = Array.from(
        new Set(
          cartItems
            .filter((item) =>
              updatedSelected.some(
                (selected) =>
                  selected.productId === item.productId &&
                  selected.variantDetails.size === item.variantDetails.size &&
                  selected.variantDetails.color === item.variantDetails.color
              )
            )
            .map((item) => item.merchant.id)
        )
      );
      setSelectedMerchants(selectedMerchants);
      return updatedSelected;
    });
  };

  const toggleSelectMerchant = (merchantId: string) => {
    const isMerchantSelected = selectedMerchants.includes(merchantId);
    const updatedSelectedItems = isMerchantSelected
      ? selectedItems.filter(
          (selectedItem) =>
            !groupedCartItems[merchantId].items.some(
              (item) =>
                item.productId === selectedItem.productId &&
                item.variantDetails.size === selectedItem.variantDetails.size &&
                item.variantDetails.color === selectedItem.variantDetails.color
            )
        )
      : [
          ...selectedItems,
          ...groupedCartItems[merchantId].items.map((item) => ({
            productId: item.productId,
            variantDetails: item.variantDetails,
          })),
        ];

    setSelectedItems(updatedSelectedItems);
    setSelectedMerchants((prevSelected) =>
      isMerchantSelected
        ? prevSelected.filter((id) => id !== merchantId)
        : [...prevSelected, merchantId]
    );
  };

  const handleQuantityChange = (productId: string, variantDetails: { size?: string; color?: string }, delta: number) => {
    const itemToUpdate = cartItems.find(
      (item) =>
        item.productId === productId &&
        item.variantDetails.size === variantDetails.size &&
        item.variantDetails.color === variantDetails.color
    );

    if (itemToUpdate) {
      const newQuantity = itemToUpdate.quantity + delta;
      if (newQuantity > 0 && newQuantity <= itemToUpdate.availableStock) {
      updateQuantity(productId, itemToUpdate.productName, variantDetails, delta);
      }
    }
  };

  const getTotalAmountForSelectedItems = () => {
    return cartItems
      .filter((item) =>
        selectedItems.some(
          (selected) =>
            selected.productId === item.productId &&
            selected.variantDetails.size === item.variantDetails.size &&
            selected.variantDetails.color === item.variantDetails.color
        )
      )
      .reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleCheckout = () => {
    const orderId = `ORD-${Math.floor(Math.random() * 1000000)}`;
    const checkoutData = selectedMerchants.map(merchantId => {
      const merchantItems = cartItems.filter(item =>
        selectedItems.some(
          selected => 
            selected.productId === item.productId &&
            selected.variantDetails.size === item.variantDetails.size &&
            selected.variantDetails.color === item.variantDetails.color
        ) && item.merchant.id === merchantId
      );
      return {
        orderId,
        merchantId,
        products: merchantItems.map(item => ({
          id: item.productId,
          name: item.productName,
          variant: item.variantDetails,
          quantity: item.quantity,
          price: item.price,
          subTotal: (item.price * item.quantity).toFixed(2)
        })),
        totalAmount: getTotalAmountForSelectedItems().toFixed(2)
      };
    });

    const totalAmount = getTotalAmountForSelectedItems().toFixed(2);

    navigate('/check-out', {
      state: { checkoutData, totalAmount },
    });
  };

  return (
    <div className="cart-page">
      <h1 className='cart-Header'>Your Cart</h1>
      <hr className="cart-divider" />
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
      <div className="cart-items">
          {Object.values(groupedCartItems).map((group) => (
            <div key={group.merchant.id} className="merchant-section">
              <div className="merchant-header">
              <input
                  checked={selectedMerchants.includes(group.merchant.id)}
                  onChange={() => toggleSelectMerchant(group.merchant.id)}
                  className="select-merchant"
                  type="checkbox"
                />
                <img
                  src={group.merchant.hero_url}
                  alt={group.merchant.merchant_name}
                  className="merchant-logo"
                  onClick={() => navigateToMerchant(group.merchant.id)}
                  style={{ cursor: 'pointer' }}
                />
                <h2 className="merchant-name" onClick={() => navigateToMerchant(group.merchant.id)}
                  style={{ cursor: 'pointer', textDecoration: 'none' }}>{group.merchant.merchant_name}

                </h2>
              </div>

              {group.items.map((item, itemIndex) => (
                <div key={itemIndex} className="cart-item">
                   <input
                    type="checkbox"
                    checked={selectedItems.some(
                      (selected) =>
                        selected.productId === item.productId &&
                        selected.variantDetails.size === item.variantDetails.size &&
                        selected.variantDetails.color === item.variantDetails.color
                    )}
                    onChange={() => toggleSelectItem(item.productId, item.variantDetails)}
                    className="select-item"
                  />
                  <IoClose
                    className="remove-icon"
                    onClick={() => handleRemoveItem(item.productId, item.variantDetails)}
                  />
                  <img
                    src={item.productMedia[0]}
                    alt={item.productName}
                    className="product-image"
                  />
                  <div className="cart-details">
                    <h3 className="cart-product-name">{item.productName.length > 20
                        ? `${item.productName.slice(0, 20)}...`
                        : item.productName}</h3>
                    <p className="cart-product-price">{item.price.toFixed(2)}</p>
                    <p className="cart-product-variant">
                      {item.variantDetails.size && item.variantDetails.color
                        ? `${item.variantDetails.size} | ${item.variantDetails.color}`
                        : item.variantDetails.size || item.variantDetails.color}
                    </p>
                    <p className="cart-product-subtotal">
                      Sub Total: {(item.price * item.quantity).toFixed(2)}
                    </p>
                    <div className="quantity-controls">
                      <button onClick={() => handleQuantityChange(item.productId, item.variantDetails, -1)} disabled={item.quantity === 1}>
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button onClick={() => handleQuantityChange(item.productId, item.variantDetails, 1)} disabled={item.quantity === item.availableStock}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {cartItems.length > 0 && (
        <div className="cart-footer">
          <div className="total-card">
            <p className="total-amount">
              <span className="total-text">Total:</span> {getTotalAmountForSelectedItems().toFixed(2)}
            </p>
            <button className="checkout-button" onClick={handleCheckout}>PROCEED TO CHECK OUT</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
