import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import FgPass from '../Pages/public/FgPass';
import VerifyPage from '../Pages/public/VerifyPage';
import SignIn from '../Pages/public/SignIn';
import SignUp from '../Pages/public/SignUp';
import SecurityLayout from 'Pages/layout/SecurityLayout';
import Verify from 'Security/Verify';
import ResetPassword from 'Pages/public/ResetPassword';

const SecurityRoute = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route path="/" element={<SecurityLayout/>}>
                    <Route path="/log-in" element={<SignIn />} />
                    <Route path="/create-account" element={<SignUp />} />
                    <Route path="/forgot-password" element={<FgPass />} />
                    <Route path="/verify" element={<VerifyPage />} />
                    <Route path="/verify/:token" element={<Verify/>}/>
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                </Route>
            </Routes>
        </React.Fragment>
    )
}

export default SecurityRoute