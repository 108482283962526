import React, { createContext, useState, ReactNode } from 'react';

interface Merchant {
  id: string;
  merchant_name: string;
  category: string;
  description: string;
  hero_url: string;
  logo_url?: string;
  status?: string;
  featured?: { title: string; mime_type: string; url: string; source: string }[] | null; 
}

interface FollowingMerchantContextProps {
  followedMerchants: Merchant[];
  toggleFollow: (merchant: Merchant) => void;
}

export const FollowingMerchantContext = createContext<FollowingMerchantContextProps | null>(null);

export const FollowingMerchantProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [followedMerchants, setFollowedMerchants] = useState<Merchant[]>([]);

  const toggleFollow = (merchant: Merchant) => {
    setFollowedMerchants((prev) => {
      const isFollowing = prev.some((m) => m.id === merchant.id);
      return isFollowing ? prev.filter((m) => m.id !== merchant.id) : [...prev, merchant];
    });
  };

  return (
    <FollowingMerchantContext.Provider value={{ followedMerchants, toggleFollow }}>
      {children}
    </FollowingMerchantContext.Provider>
  );
};
