import { IParams, tryFetch } from "Helpers/FetchAPI"

export const tryAuthenticate = async (payload: any) => {
    let params: IParams = {
        url: '/sec/login',
        method: "POST",
        payload
    }
    return await tryFetch(params);
}

export const tryRefresh = async (token: string) => {
    let params: IParams = {
        url: '/g/refresh',
        method: "POST",
        token
    }
    return await tryFetch(params);
}

export const logout = async (token: string) => {
    let params: IParams = {
        url: '/g/logout',
        method: "POST",
        token
    }
    return await tryFetch(params);
}

export const fetchProfile = async (token: string) => {
    let params: IParams = {
        url: '/g/profile',
        method: "GET",
        token
    }
    return await tryFetch(params);
}

export const TryVerify =  async (token: string) => {
    let params: IParams = {
        url: `/verify/${token}`,
        method: "POST",
        payload: {},
    }
    return await tryFetch(params);
}

export const TryVerifyReset =  async (token: string) => {
    let params: IParams = {
        url: `/verify-reset/${token}`,
        method: "POST",
        payload: {},
    }
    return await tryFetch(params);
}

export const TryResetPassword =  async (payload:object, token: string) => {
    let params: IParams = {
        url: `/sec/reset-password`,
        method: "POST",
        payload: payload,
        token
    }
    return await tryFetch(params);
}